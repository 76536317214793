export default {
  id: String,
  account: Object,
  user: Object,
  withdrawal_datetime: v => v,
  completed: Boolean,
  amount: (v, item) => (v === 0 ? (item.requested_amount) : v),
  profit_share_amount: (v, item) => (item.requested_amount !== null ? ((item.requested_amount) * (item.profit_share / 100)) : (item.amount * (item.profit_share / 100))),
  profit_share: Number,
  confirmed_at: v => v,
  createdAt: v => v,
  state: String,
  comment: String,
  withdrawal_count: Number,
  platform: String,
}
