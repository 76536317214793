const Status = Object.freeze({
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
  CREATING: 'creating',
  UPDATING: 'updating',
  DELETING: 'deleting',
})

export function makeResource(service, module = {}) {
  const state = () => ({
    status: Status.INITIAL,
    data: [],
    limit: 25,
    sort: {
      column: null,
      dir: 'DESC',
    },
    filters: {},
    metaData: {
      totalCount: null,
      rows: null,
      page: 1,
    },
    error: null,
    row: null,
    ...(module.state?.() ?? {}),
  })

  const mutations = {
    LOAD(state) {
      if (state.status === Status.INITIAL) {
        state.status = Status.LOADING
      }
    },

    DONE(state, data) {
      if (
        [
          Status.LOADING,
          Status.CREATING,
          Status.UPDATING,
          Status.DELETING,
        ].includes(state.status)
      ) {
        state.status = Status.SUCCESS
        state.metaData = {
          totalCount: data.totalCount,
          rows: data.data.length,
          page: state.metaData.page,
        }
        state.data = data.data
      }
    },

    DONE_ROW(state, data) {
      state.row = data
    },

    FAIL(state, error) {
      if (
        [
          Status.LOADING,
          Status.CREATING,
          Status.UPDATING,
          Status.DELETING,
        ].includes(state.status)
      ) {
        state.status = Status.FAILURE
        state.error = error
      }
    },

    RELOAD(state) {
      if (state.status === Status.SUCCESS) {
        state.status = Status.LOADING
      }
    },

    RETRY(state) {
      if (state.status === Status.FAILURE) {
        state.status = Status.LOADING
      }
    },

    CREATE(state) {
      if (state.status === Status.SUCCESS) {
        state.status = Status.CREATING
      }
    },

    UPDATE(state) {
      if (state.status === Status.SUCCESS) {
        state.status = Status.UPDATING
      }
    },

    DELETE(state) {
      if (state.status === Status.SUCCESS) {
        state.status = Status.DELETING
      }
    },

    SUCCESS(state) {
      state.status = Status.SUCCESS
    },

    RESET(state) {
      state.status = Status.INITIAL
      state.data = null
      state.error = null
    },

    CHANGE_PAGE(state, page) {
      state.metaData.page = page
    },

    SET_LIMIT(state, data) {
      state.limit = data
    },

    SET_SORT(state, data) {
      state.sort = data
    },

    SET_FILTERS(state, data) {
      state.filters = data
    },

    ...module.mutations,
  }

  const actions = {
    loadAll({ commit, state }) {
      if (state.status === Status.INITIAL) {
        commit('LOAD')

        return service
          .getAll({
            page: state.metaData.page, limit: state.limit, sort: state.sort, filters: state.filters,
          })
          .then(data => {
            commit('DONE', data)
            return data
          })
          .catch(error => {
            commit('FAIL', error)
            return Promise.reject(error)
          })
      }
      return Promise.resolve(state.data)
    },

    reloadAll({ commit, state, getters }) {
      if (getters.isIdle) {
        commit('RELOAD')

        return service
          .getAll({
            page: state.metaData.page, limit: state.limit, sort: state.sort, filters: state.filters,
          })
          .then(data => {
            commit('DONE', data)
            return data
          })
          .catch(error => {
            commit('FAIL', error)
            return Promise.reject(error)
          })
      }
      return Promise.resolve(state.data)
    },

    changePage({ commit, dispatch }, { page }) {
      commit('CHANGE_PAGE', page)
      dispatch('reloadAll')
    },

    setPage({ commit }, { page }) {
      commit('CHANGE_PAGE', page)
    },

    changeLimit({ commit, dispatch }, { limit }) {
      commit('SET_LIMIT', limit)
      dispatch('reloadAll')
    },

    setLimit({ commit }, { limit }) {
      commit('SET_LIMIT', limit)
    },

    changeSort({ commit, dispatch }, { column, dir }) {
      commit('SET_SORT', { column, dir })
      dispatch('reloadAll')
    },

    setSort({ commit }, { column, dir }) {
      commit('SET_SORT', { column, dir })
    },

    setFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    changeFilters({ commit, dispatch }, filters) {
      commit('SET_FILTERS', filters)
      dispatch('reloadAll')
    },

    get({ commit }, id) {
      return service.get(id).then(item => {
        commit('DONE_ROW', item)
        return item
      }).catch(error => {
        commit('FAIL', error)
        return Promise.reject(error)
      })
    },

    delete({ dispatch, commit }, id) {
      commit('DELETE')
      let entityId = id
      let reload = true
      if (typeof id === 'object') {
        entityId = id.id
        reload = typeof id.autoReload === 'undefined' || id.autoReload === true
      }

      return service.delete(entityId).then(() => {
        commit('SUCCESS')
        if (reload) {
          dispatch('reloadAll')
        }
      }).catch(error => {
        commit('FAIL', error)
        return Promise.reject(error)
      })
    },

    create({ dispatch, commit }, payload) {
      commit('CREATE')
      return service.create(payload.data).then(() => {
        commit('SUCCESS')
        if (typeof payload.autoReload === 'undefined' || payload.autoReload === true) {
          dispatch('reloadAll')
        }
      }).catch(error => {
        commit('FAIL', error)
        return Promise.reject(error)
      })
    },

    update({ dispatch, commit }, payload) {
      commit('UPDATE')
      return service.update(payload.id, payload.data).then(response => {
        commit('SUCCESS')
        if (typeof payload.autoReload === 'undefined' || payload.autoReload === true) {
          dispatch('reloadAll')
        }
        return Promise.resolve(response)
      }).catch(error => {
        commit('FAIL', error)
        return Promise.reject(error)
      })
    },

    ...module.actions,
  }

  const getters = {
    hasData(state) {
      return state.data !== null
    },

    isBusy(state) {
      return [
        Status.LOADING,
        Status.CREATING,
        Status.UPDATING,
        Status.DELETING,
      ].includes(state.status)
    },

    isInitial(state) {
      return state.status === Status.INITIAL
    },

    isIdle(_, getters) {
      return !getters.isBusy
    },

    ...module.getters,
  }

  return {
    namespaced: module.namespaced ?? true,
    state,
    actions,
    getters,
    mutations,
  }
}
