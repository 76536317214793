export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/order/OrderIndex.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders-beta',
    name: 'orders-beta',
    component: () => import('@/views/order/OrderIndexBeta.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:id',
    name: 'order-detail',
    component: () => import('@/views/order/detail/OrderDetail.vue'),
    meta: {
      pageTitle: 'Order',
      breadcrumb: [
        {
          text: 'Orders',
          active: false,
          to: { name: 'orders-beta' },
        },
        {
          text: 'Order detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders-ext/:id',
    name: 'order-detail-ext',
    component: () => import('@/views/order/detail/OrderDetailExt.vue'),
    meta: {
      pageTitle: 'Order',
      breadcrumb: [
        {
          text: 'Orders',
        },
        {
          text: 'Order detail',
          active: true,
        },
      ],
    },
  },
]
