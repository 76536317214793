import { $themeBreakpoints } from '@themeConfig'
import { make } from 'vuex-pathify'
import * as defaults from './defaults'

const state = () => ({
  windowWidth: 0,
  shallShowOverlay: false,
  apiResponseState: { ...defaults.API_RESPONSE_STATE_TEMPLATE },
  showAppInstallMessage: null,
  showTags: true,
})

const actions = {
  updateApiResponseState({ commit }, response) {
    commit('SET_API_RESPONSE_STATE', response)
  },

  clearApiResponseState({ commit }) {
    commit('SET_API_RESPONSE_STATE', { ...defaults.API_RESPONSE_STATE_TEMPLATE })
  },

  showTags({ commit }) {
    commit('SET_SHOW_TAGS', true)
  },

  hideTags({ commit }) {
    commit('SET_SHOW_TAGS', false)
  },
}

const getters = {
  ...make.getters(state),
  currentBreakPoint: state => {
    const { windowWidth } = state
    if (windowWidth >= $themeBreakpoints.xl) return 'xl'
    if (windowWidth >= $themeBreakpoints.lg) return 'lg'
    if (windowWidth >= $themeBreakpoints.md) return 'md'
    if (windowWidth >= $themeBreakpoints.sm) return 'sm'
    return 'xs'
  },
  isMobile: state => {
    const { windowWidth } = state
    if (windowWidth < $themeBreakpoints.lg) { return true }
    return false
  },
}

const mutations = {
  ...make.mutations(state),
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
