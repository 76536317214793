import { make } from 'vuex-pathify'
import AuthAPI from '@/api/auth'

import * as defaults from './defaults'

const state = () => ({
  appLoginPending: false,

  appRegistrationPending: false,

  credentialsModel: { ...defaults.CREDENTIALS_MODEL_TEMPLATE },
  registrationModel: { ...defaults.REGISTRATION_MODEL_TEMPLATE },

  token: null,
  me: null,
  logged: false,
})

const actions = {
  login({ commit, state, dispatch }) {
    if (state.token) {
      return
    }
    commit('SET_APP_LOGIN_PENDING', true)

    return AuthAPI.login(state.credentialsModel)
      .then(response => {
        commit(
          'SET_CREDENTIALS_MODEL',
          { ...defaults.CREDENTIALS_MODEL_TEMPLATE },
        )
        commit('SET_TOKEN', response.access_token)
        dispatch('getMe')
      })
      .finally(() => {
        commit('SET_APP_LOGIN_PENDING', false)
      })
  },

  getMe({ commit, state }) {
    if (state.token) {
      if (state.me === null) {
        return AuthAPI.getMe().then(response => {
          commit('SET_ME', response)
          commit('SET_LOGGED', true)
        })
      }
    }
  },

  logout({ commit, dispatch, state }) {
    if (!state.token) {
      return
    }

    dispatch('clearModules', null, { root: true })

    commit('SET_TOKEN', null)
  },

  // eslint-disable-next-line no-empty-pattern
  changePassword({}, { ...data }) {
    return AuthAPI.changePassword(data)
  },

  updateUser({ commit }, { user }) {
    return AuthAPI.patchUser(user).then(response => {
      commit('SET_USER', response)
    })
  },

  clearModule({ commit }) {
    commit('SET_APP_LOGIN_PENDING', false)
    commit('SET_APP_REGISTRATION_PENDING', false)
    commit('SET_CREDENTIALS_MODEL', { ...defaults.CREDENTIALS_MODEL_TEMPLATE })
    commit('SET_REGISTRATION_MODEL', { ...defaults.REGISTRATION_MODEL_TEMPLATE })
    commit('SET_TOKEN', null)
    commit('SET_ME', null)
    commit('SET_LOGGED', false)
  },

}

const getters = {
  ...make.getters(state),
  isAuthenticated(state) {
    return !!state.token
  },
  roles(state) {
    return (state.me && state.me.roles) ? state.me.roles : []
  },
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
