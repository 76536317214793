export default {
  id: String,
  account_id: String,
  account_type_name: String,
  account_subtype_name: String,
  investor_password: String,
  password: String,
  deposit: Number,
  balance: Number,
  createdAt: v => v,
  start_datetime: v => v,
  end_datetime: v => v,
  close_datetime: v => v,
  signedAt: v => v,
  confirmedAt: v => v,
  is_active: Boolean,
  doc_id: String,
  state: String,
  server: Object,
  currency: Object,
  user: Object,
  order: Object,
  parent: Object,
  tags: Object,
  under_review: Boolean,
  risk_score: Number,
  risk_score_results: Object,
  rule_inheritance: Object,
  rule_max_days: String,
  rule_max_daily_drawdown: String,
  rule_max_total_drawdown: String,
  rule_min_trading_days: String,
  rule_min_profit: String,
  rule_close_at_end: Boolean,
  rule_first_withdrawal_after: String,
  rule_withdrawal_after: String,
  profit_split: Number,
  execution_type: String,
  extras_tags: (v, item) => {
    const tags = item.extras_tags
    if (item.phase_move_check !== null) tags.push(`Cron check ${item.phase_move_check}`)
    if (item.equity_check !== null) tags.push(`AVENTUS check ${item.equity_check}`)
    if (item.risk_score_results && Object.keys(item.risk_score_results).includes('pass_with_one_trade')) tags.push({ variant: 'light-danger', name: 'PASS WITH ONE TRADE' })

    return tags
  },
  comment: String,
}
