export default {
  id: String,
  partner: Object,
  withdrawal_datetime: v => v,
  amount: Number,
  confirmed_at: v => v,
  createdAt: v => v,
  state: String,
  platform: String,
}
