import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import store from '@/store'

function getHeaders() {
  const headers = { Authorization: '', 'Content-Type': '' }
  const { token } = store.state.auth
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  headers['Content-Type'] = 'application/json'
  return headers
}
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
  fetch: (uri, options) => {
    options.headers = getHeaders()
    return fetch(uri, options)
  },
})

const cache = new InMemoryCache()

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

export default apolloClient
