export default [
  {
    path: '/prime-statistics',
    name: 'prime-statistics',
    component: () => import('@/views/prime-statistics/PrimeStatisticsIndex.vue'),
    meta: {
      pageTitle: 'Prime statistics',
      breadcrumb: [
        {
          text: 'Prime statistics',
          active: true,
        },
      ],
    },
  },
]
