import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    isVerticalMenuActive: false,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    SET_IS_VERTICAL_MENU_ACTIVE(state, val) {
      state.isVerticalMenuActive = val
    },
  },
  actions: {},
}
