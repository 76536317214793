export default [
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('@/views/withdrawal/WithdrawalIndex.vue'),
    meta: {
      pageTitle: 'Withdrawals',
      breadcrumb: [
        {
          text: 'Withdrawals',
          active: true,
        },
      ],
    },
  },
  {
    path: '/withdrawal-beta',
    name: 'withdrawal-beta',
    component: () => import('@/views/withdrawal/WithdrawalIndexBeta.vue'),
    meta: {
      pageTitle: 'Withdrawals',
      breadcrumb: [
        {
          text: 'Withdrawals',
          active: true,
        },
      ],
    },
  },
  {
    path: '/withdrawal-approved',
    name: 'withdrawal-approved',
    component: () => import('@/views/withdrawal/WithdrawalApprovedBySupportIndex.vue'),
    meta: {
      pageTitle: 'Approved withdrawals',
      breadcrumb: [
        {
          text: 'Approved withdrawals',
          active: true,
        },
      ],
    },
  },
]
