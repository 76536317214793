export default [
  {
    path: '/settings/roles/',
    name: 'roles',
    component: () => import('@/views/security/role/RolesIndex.vue'),
    meta: {
      pageTitle: 'Role',
      breadcrumb: [
        {
          text: 'Security',
          active: true,
        },
        {
          text: 'Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/roles/:id',
    name: 'role-detail',
    component: () => import('@/views/security/role/detail/RoleDetail.vue'),
    meta: {
      pageTitle: 'Role',
      breadcrumb: [
        {
          text: 'Security',
          active: true,
        },
        {
          text: 'Roles',
          active: false,
          to: { name: 'roles' },
        },
        {
          text: 'Role detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/country',
    name: 'settings-country',
    component: () => import('@/views/settings/country-settings/CountrySettingsIndex.vue'),
    meta: {
      pageTitle: 'Country settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: false,
        },
        {
          text: 'Country settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/country/:id',
    name: 'settings-country-detail',
    component: () => import('@/views/settings/country-settings/detail/CountrySettingsDetail.vue'),
    meta: {
      pageTitle: 'Country settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Country settings',
          active: false,
          to: { name: 'settings-country' },
        },
      ],
    },
  },
]
