export default {
  id: String,
  ticket_number: String,
  subject: String,
  content: String,
  response: String,
  state: String,
  user: Object,
  files: Object,
  response_files: Object,
  resolver: Object,
  hide: Boolean,
  ip: String,
  createdAt: v => v,
  updatedAt: v => v,
  files_deleted_at: v => v,
  response_files_deleted_at: v => v,
}
