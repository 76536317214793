import moment from 'moment'
import 'moment-timezone'

const getLocale = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language)

const formatDate = value => {
  if (value) {
    const date = new Date(value)
    // return date.toLocaleDateString(getLocale())
    return date.toLocaleDateString(getLocale(), { timeZone: 'UTC' })
  }
}

const formatDateTime = value => {
  if (value) {
    const date = new Date(value)
    // return `${date.toLocaleDateString(getLocale())} ${date.toLocaleTimeString(getLocale())}`
    return `${date.toLocaleDateString(getLocale(), { timeZone: 'UTC' })} ${date.toLocaleTimeString(getLocale(), { timeZone: 'UTC', timeZoneName: 'short' })}`
  }
}

const utcDate = value => {
  if (value) {
    const date = new Date(value)
    return `${date.toLocaleDateString(getLocale(), { timeZone: 'UTC' })} ${date.toLocaleTimeString(getLocale(), { timeZone: 'UTC', timeZoneName: 'short' })}`
  }
}

const formatCurrency = (value, currency) => {
  const intl = new Intl.NumberFormat(getLocale(), { style: 'currency', currency })
  return intl.format(value)
}

const formatNumber = (value, denote = '', decimalPlaces = 2) => {
  if (denote === 'k') {
    value /= 1000
  }
  if (denote === 'm') {
    value /= 1000000
  }
  const factorOfTen = 10 ** decimalPlaces
  value = Math.round(value * factorOfTen) / factorOfTen
  const intl = new Intl.NumberFormat(getLocale())

  return `${intl.format(value)}${denote}`
}

const utcToMtDate = utcDate => {
  if (typeof utcDate !== 'string') {
    throw new Error('utcDate must be a string in YYYY-MM-DD HH:MM:SS format')
  }
  const utcMoment = moment.utc(utcDate, 'YYYY-MM-DD HH:mm:ss')
  const nyTimezone = 'America/New_York'
  const nyMoment = utcMoment.clone().tz(nyTimezone)

  const dstHours = nyMoment.isDST() ? 3 : 2

  const mtMoment = utcMoment.clone().add(dstHours, 'hours')

  return new Date(mtMoment.utc().format('YYYY-MM-DD'))
}

const formatFloat = (value, decimal = 2) => parseFloat(value).toFixed(decimal)

export {
  formatDate, formatCurrency, formatDateTime, formatNumber, utcDate, formatFloat, utcToMtDate,
}
