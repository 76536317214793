export default [
  {
    path: '/individual-analysis',
    name: 'individual-analysis',
    component: () => import('@/views/trading-history/individual-analysis/IndividualAnalysisIndex.vue'),
    meta: {
      pageTitle: 'Individual trading history analysis',
      breadcrumb: [
        {
          text: 'Individual trading history analysis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/hedging-analysis',
    name: 'hedging-analysis',
    component: () => import('@/views/trading-history/hedging-analysis/HedgingAnalysisIndex.vue'),
    meta: {
      pageTitle: 'Hedging analysis',
      breadcrumb: [
        {
          text: 'Hedging trading history analysis',
          active: true,
        },
      ],
    },
  },
]
