export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
      allowAnonymous: true,
    },
  },
]
