import axiosInstance from '@/plugins/axios'

export default {
  login(credentials) {
    const url = 'oauth2/token'
    const data = {
      ...credentials,
      client_id: process.env.VUE_APP_CLIENT,
      client_secret: process.env.VUE_APP_SECRET,
      grant_type: 'password',
      scope: 'admin',
    }
    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  getMe() {
    const url = 'admin/users/me'

    return axiosInstance.get(url)
      .then(response => response.data)
  },
}
