export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/HomeIndex.vue'),
    meta: {
      pageTitle: 'Home',
    },
  },
]
