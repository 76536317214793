export default [
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('@/views/ticket/TicketsIndex.vue'),
    meta: {
      pageTitle: 'Tickets',
      breadcrumb: [
        {
          text: 'Tickets',
          active: true,
        },
      ],
    },
  },
]
