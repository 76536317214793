const CREDENTIALS_MODEL_TEMPLATE = {
  username: null,
  password: null,
  twoFactor: '',
}

const REGISTRATION_MODEL_TEMPLATE = {
  firstName: null,
  lastName: null,
  username: null,
  password: null,
  phone: null,
  country: null,
  title: null,
}

const USER_MODEL = {
  id: null,
  firstName: null,
  lastName: null,
}

export { CREDENTIALS_MODEL_TEMPLATE, REGISTRATION_MODEL_TEMPLATE, USER_MODEL }
