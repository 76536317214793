export default {
  id: String,
  account: Object,
  user: Object,
  full_name: String,
  address: String,
  doc_no: String,
  doc_type: String,
  state: String,
  doc_expiration: v => v,
  acceptedAt: v => v,
  declinedAt: v => v,
  createdAt: v => v,
  updatedAt: v => v,
  ip: String,
  files: v => v,
  supportApprovedAt: v => v,
  approval_required: Boolean,
}
