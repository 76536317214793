export default [
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('@/views/account/AccountIndex.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts-beta',
    name: 'accounts-beta',
    component: () => import('@/views/account/AccountIndexBeta.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts-ext',
    name: 'accounts-ext',
    component: () => import('@/views/account/AccountIndexExternal.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Accounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/phases',
    name: 'accounts-phases',
    component: () => import('@/views/account/phases/PhasesIndex.vue'),
    meta: {
      pageTitle: 'Phases',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts' },
        },
        {
          text: 'Phases',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/phases-beta',
    name: 'accounts-phases-beta',
    component: () => import('@/views/account/phases/PhasesBetaIndex.vue'),
    meta: {
      pageTitle: 'Phases',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts' },
        },
        {
          text: 'Phases',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/waiting-phases',
    name: 'waiting-phases',
    component: () => import('@/views/account/waiting-phases/WaitingPhasesIndex.vue'),
    meta: {
      pageTitle: 'Phases waiting to move',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts' },
        },
        {
          text: 'Phases waiting to move',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/detail/:id',
    name: 'account-detail',
    component: () => import('@/views/account/detail/AccountDetail.vue'),
    meta: {
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts-beta' },
        },
        {
          text: 'Account detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/detail-ext/:id',
    name: 'account-detail-ext',
    component: () => import('@/views/account/detail/AccountDetailExternal.vue'),
    meta: {
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts-ext' },
        },
        {
          text: 'Account detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/new',
    name: 'accounts-new',
    component: () => import('@/views/account/new/NewAccount.vue'),
    meta: {
      pageTitle: 'New account',
      breadcrumb: [
        {
          text: 'Accounts',
          active: false,
          to: { name: 'accounts' },
        },
        {
          text: 'New account',
          active: true,
        },
      ],
    },
  },
]
