export default [
  {
    path: '/affiliate',
    name: 'affiliate',
    component: () => import('@/views/affiliate/AffiliateIndex.vue'),
    meta: {
      pageTitle: 'Affiliate',
      breadcrumb: [
        {
          text: 'Affiliate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/affiliate/:id',
    name: 'affiliate-detail',
    component: () => import('@/views/affiliate/detail/AffiliateDetail.vue'),
    meta: {
      pageTitle: 'Affiliate detail',
      breadcrumb: [
        {
          text: 'Affiliate',
          active: false,
          to: { name: 'affiliate' },
        },
        {
          text: 'Affiliate detail',
          active: true,
        },
      ],
    },
  },
]
