export default [
  {
    path: '/tools/mailing',
    name: 'tools-mailing',
    component: () => import('@/views/tools/mailing/MailingIndex.vue'),
    meta: {
      pageTitle: 'Mass mailing',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Mass mailing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/tags',
    name: 'tools-tags',
    component: () => import('@/views/tools/tag/TagIndex.vue'),
    meta: {
      pageTitle: 'Tags',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/announcements',
    name: 'tools-announcements',
    component: () => import('@/views/tools/announcement/AnnouncementIndex.vue'),
    meta: {
      pageTitle: 'Announcements',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Announcements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/blacklist',
    name: 'tools-blacklist',
    component: () => import('@/views/tools/blacklist/BlacklistIndex.vue'),
    meta: {
      pageTitle: 'Ip blacklist',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Ip blacklist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/ip-check/:id?',
    name: 'tools-ip-check',
    component: () => import('@/views/tools/ip-check/IpCheckIndex.vue'),
    meta: {
      pageTitle: 'Ip check',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Ip check',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/vpn-logins',
    name: 'tools-vpn-logins',
    component: () => import('@/views/tools/vpn-logins/VpnLoginsIndex.vue'),
    meta: {
      pageTitle: 'VPN Logins',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'VPN Logins',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/risk-score/:id?',
    name: 'tools-risk-score',
    component: () => import('@/views/tools/risk-score/RiskScoreIndex.vue'),
    meta: {
      pageTitle: 'Risk score',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Risk score',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/exports',
    name: 'tools-exports',
    component: () => import('@/views/tools/exports/ExportsIndex.vue'),
    meta: {
      pageTitle: 'Export data',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Exports',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tools/linkages',
    name: 'tools-linkages',
    component: () => import('@/views/tools/account-linkages/AccountLinkageIndex.vue'),
    meta: {
      pageTitle: 'Account linkages from risk score analysis',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Account linkages from risk score analysis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/calendar',
    name: 'tools-news',
    component: () => import('@/views/tools/news/NewsIndex.vue'),
    meta: {
      pageTitle: 'Economic calendar',
      breadcrumb: [
        {
          text: 'Tools',
          active: true,
        },
        {
          text: 'Economic calendar',
          active: true,
        },
      ],
    },
  },
]
