export default {
  id: String,
  order_number: String,
  user: Object,
  price: Number,
  createdAt: v => v,
  payment_datetime: v => v,
  state: String,
  userFullName: {
    resolver: (v, item) => `${item.user.firstName} ${item.user.lastName}`,
  },
  processor: String,
  tags: Object,
  payment_methods: Object,
  discount_code: Object,
}
