import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import login from './login'
import home from './home'
import affiliate from './affiliate'
import users from './users'
import payouts from './payouts'
import accounts from './accounts'
import orders from './orders'
import tools from './tools'
import discounts from './discounts'
import copyTrading from './copyTrading'
import withdrawal from './withdrawal'
import agreement from './agreement'
import settings from './settings'
import withdrawalStatistics from './withdrawalStatistics'
import marketingStatistics from '@/router/marketingStatistics'
import tickets from './tickets'
import primeStatistics from '@/router/primeStatistics'
import finStatistics from '@/router/finStatistics'
import affiliateStatistics from '@/router/affiliateStatistics'
import promo from '@/router/promo'
import refunds from '@/router/refunds'
import partner from '@/router/partner'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...login,
    ...accounts,
    ...home,
    ...affiliate,
    ...users,
    ...payouts,
    ...orders,
    ...tools,
    ...discounts,
    ...copyTrading,
    ...agreement,
    ...withdrawal,
    ...settings,
    ...withdrawalStatistics,
    ...marketingStatistics,
    ...finStatistics,
    ...primeStatistics,
    ...tickets,
    ...affiliateStatistics,
    ...promo,
    ...refunds,
    ...partner,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        allowAnonymous: true,
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
    },

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (!isAuthenticated && !to.meta.allowAnonymous && !['login'].includes(to.name)) {
    return next('/login')
  }
  if (['login'].includes(to.name) && isAuthenticated) {
    return next('/')
  }

  if (to.meta.pageTitle) {
    document.title = `${to.meta.pageTitle} | E8 Admin`
  }

  return next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.add('hide')
    setTimeout(() => {
      appLoading.style.display = 'none'
    }, 500)
  }
})

export default router
