export default [
  {
    path: '/affiliate-statistics',
    name: 'affiliate-statistics',
    component: () => import('@/views/statistics/affiliate-statistics/AffiliateStatisticsIndex.vue'),
    meta: {
      pageTitle: 'Affiliate statistics',
    },
  },
]
