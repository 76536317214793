export default {
  id: String,
  title: String,
  content: String,
  style: String,
  start_datetime: v => v,
  end_datetime: v => v,
  createdAt: v => v,
  active: Boolean,
}
