import axiosInstance from '@/plugins/axios'

const BASE_URL = 'admin'

function mapSchema({ entity, schema }) {
  const result = {}
  Object.keys(schema).forEach(key => {
    const config = schema[key]
    const resolver = config.resolver || config
    const value = entity[config.field] || entity[key]
    /* Object.defineProperty(result, key, {
      get() {
        return resolver(value, entity)
      },
    }) */
    result[key] = resolver(value, entity)
  }, {})
  return result
}

export default function makeService({ schema, type }) {
  return {
    get(id) {
      return axiosInstance.get(`${BASE_URL}/${type}/${id}`)
        .then(response => response.data)
    },
    getAll({
      page, filters, limit, sort,
    }) {
      const sortArray = {}
      if (sort.column !== null) sortArray[`sort[${sort.column}]`] = sort.dir
      return axiosInstance.get(`${BASE_URL}/${type}`, {
        params: {
          page, filters, limit, ...sortArray,
        },
      })
        .then(response => ({
          data: response.data.map(entity => mapSchema({ entity, schema })),
          totalCount: parseInt(response.headers['x-total-count'], 10),
        }))
    },
    create(data) {
      return axiosInstance.post(`${BASE_URL}/${type}`, data).then(response => response.data)
    },
    update(id, data) {
      return axiosInstance.patch(`${BASE_URL}/${type}/${id}`, data).then(response => response.data)
    },
    delete(id) {
      return axiosInstance.delete(`${BASE_URL}/${type}/${id}`).then(response => response.data)
    },
  }
}
