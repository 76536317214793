export default [
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner/PartnersIndex.vue'),
    meta: {
      pageTitle: 'Partners',
      breadcrumb: [
        {
          text: 'Partners',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner/:id',
    name: 'partner-detail',
    component: () => import('@/views/partner/detail/PartnerDetailIndex.vue'),
    meta: {
      pageTitle: 'Partner detail',
      breadcrumb: [
        {
          text: 'Partners',
          active: false,
          to: { name: 'partner' },
        },
        {
          text: 'Partner detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-withdrawals',
    name: 'partner-withdrawals',
    component: () => import('@/views/partner/PartnerWithdrawalIndex.vue'),
    meta: {
      pageTitle: 'Partners withdrawals',
      breadcrumb: [
        {
          text: 'Partners withdrawals',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-keys',
    name: 'partner-keys',
    component: () => import('@/views/partner/KeysToApprove.vue'),
    meta: {
      pageTitle: 'Partners keys to approve',
      breadcrumb: [
        {
          text: 'Partners keys to approve',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom-discounts',
    name: 'custom-discounts',
    component: () => import('@/views/partner/DiscountToApprove.vue'),
    meta: {
      pageTitle: 'Custom discount codes to approve',
      breadcrumb: [
        {
          text: 'Custom discount codes to approve',
          active: true,
        },
      ],
    },
  },
]
