export default {
  id: String,
  promo: Object,
  user: Object,
  user_state: String,
  state: String,
  comment: String,
  createdAt: v => v,
  updatedAt: v => v,
}
