export default [
  {
    path: '/fin-statistics',
    name: 'fin-statistics',
    component: () => import('@/views/statistics/fin-statistics/FinStatisticsIndex.vue'),
    meta: {
      pageTitle: 'Fin statistics',
    },
  },
]
