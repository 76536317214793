import auth from './auth'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import account from './account'
import { makeResource } from '@/utils/resource'
import makeService from '@/utils/service'
import orderModel from '@/model/order'
import userModel from '@/model/user'
import accountModel from '@/model/account'
import discountModel from '@/model/discount'
import affModel from '@/model/partner'
import withdrawalModel from '@/model/withdrawal'
import tagModel from '@/model/tag'
import announcementModel from '@/model/announcement'
import roleModel from '@/model/role'
import withdrawalSpendModel from '@/model/withdrawal-spend'
import contractConsentModel from '@/model/contract-consent'
import ticketModel from '@/model/ticket'
import vpnLoginAttempt from '@/model/VpnLoginAttempt'
import paymentMethods from '@/model/paymentMethods'
import accountLinkageModel from '@/model/accountLinkage'
import promoToUserModel from '@/model/promoToUser'
import promoModel from '@/model/promo'
import accountLinkageSummaryModel from '@/model/accountLinkageSummary'
import serverModel from '@/model/server'
import countryModel from '@/model/country'
import orderRefundModel from '@/model/orderRefund'
import gqlFilter from '@/store/modules/gql-filter'
import PartnerModel from '@/model/partnerModel'
import PartnerWithdrawalModel from '@/model/partner-withdrawal'

export default {
  auth,
  app,
  appConfig,
  verticalMenu,
  gqlFilter,
  partnersList: makeResource(makeService({ schema: affModel, type: 'affiliate' })),
  registeredUsersList: makeResource(makeService({ schema: userModel, type: 'users' })),
  usersList: makeResource(makeService({ schema: userModel, type: 'users' })),
  account,
  accountsList: makeResource(makeService({ schema: accountModel, type: 'accounts' })),
  // store for select components
  accountsSelectorList: makeResource(makeService({ schema: accountModel, type: 'accounts' })),
  phasesList: makeResource(makeService({ schema: accountModel, type: 'accounts' })),
  agreementsList: makeResource(makeService({ schema: accountModel, type: 'accounts' })),
  discountsList: makeResource(makeService({ schema: discountModel, type: 'discounts' })),
  ordersList: makeResource(makeService({ schema: orderModel, type: 'orders' })),

  withdrawalsList: makeResource(makeService({ schema: withdrawalModel, type: 'withdrawals' })),
  withdrawalsApprovedList: makeResource(makeService({ schema: withdrawalModel, type: 'withdrawals' })),
  withdrawalsStatisticsList: makeResource(makeService({ schema: withdrawalModel, type: 'withdrawals' })),
  tagsList: makeResource(makeService({ schema: tagModel, type: 'tags' })),

  rolesList: makeResource(makeService({ schema: roleModel, type: 'security/roles' })),
  announcementsList: makeResource(makeService({ schema: announcementModel, type: 'announcements' })),

  withdrawalSpendList: makeResource(makeService({ schema: withdrawalSpendModel, type: 'withdrawal-statistics/users' })),

  contractConsentsList: makeResource(makeService({ schema: contractConsentModel, type: 'consents' })),

  ticketsList: makeResource(makeService({ schema: ticketModel, type: 'tickets' })),

  vpnLoginsList: makeResource(makeService({ schema: vpnLoginAttempt, type: 'tools/vpn-logins' })),
  paymentMethodsList: makeResource(makeService({ schema: paymentMethods, type: 'payment-methods' })),
  accountLinkageList: makeResource(makeService({ schema: accountLinkageModel, type: 'tools/linkage' })),
  accountLinkageSummaryList: makeResource(makeService({ schema: accountLinkageSummaryModel, type: 'tools/linkage-summary' })),
  promoToUserList: makeResource(makeService({ schema: promoToUserModel, type: 'promo-detail' })),
  promosList: makeResource(makeService({ schema: promoModel, type: 'promos' })),
  serversList: makeResource(makeService({ schema: serverModel, type: 'accounts/servers' })),
  countriesList: makeResource(makeService({ schema: countryModel, type: 'accounts/settings/countries' })),

  refundList: makeResource(makeService({ schema: orderRefundModel, type: 'refunds' })),

  // new affiliate lists
  partnerList: makeResource(makeService({ schema: PartnerModel, type: 'partner' })),
  partnerWithdrawalList: makeResource(makeService({ schema: PartnerWithdrawalModel, type: 'partner-withdrawal' })),
}
