export default [
  {
    path: '/promo',
    name: 'promo',
    component: () => import('@/views/promo/PromoIndex.vue'),
    meta: {
      pageTitle: 'Promo',
      breadcrumb: [
        {
          text: 'Promo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promo/detail/:id',
    name: 'promo-detail',
    component: () => import('@/views/promo/detail/PromoDetail.vue'),
    meta: {
      pageTitle: 'Promo detail',
      breadcrumb: [
        {
          text: 'Promo',
          active: true,
        },
      ],
    },
  },
]
