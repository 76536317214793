import { make } from 'vuex-pathify'
import AccountAPI from '@/api/account'

const state = () => ({
  accounts: null,
  phases: null,
  account: null,
  subTypes: null,
  currencies: null,
})

const actions = {
  getAccountSubtypes({ commit, state }, { force }) {
    if (state.accounts === null || force) {
      return AccountAPI.getAccountSubtypes()
        .then(response => {
          commit('SET_SUB_TYPES', response)
        })
    }
  },

  getCurrencies({ commit, state }, { force }) {
    if (state.accounts === null || force) {
      return AccountAPI.getCurrencies()
        .then(response => {
          commit('SET_CURRENCIES', response)
        })
    }
  },
}

const getters = {
  ...make.getters(state),
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
