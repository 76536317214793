import { make } from 'vuex-pathify'

const state = () => ({
  filters: {},
})

const actions = {
  addFilter({ state }, { filter, type }) {
    state.filters[type] = filter
  },
}

const getters = {
  ...make.getters(state),
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
