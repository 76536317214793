export default [
  {
    path: '/withdrawal-statistics',
    name: 'withdrawal-statistics',
    component: () => import('@/views/statistics/withdrawal-statistics/StatisticsIndex.vue'),
    meta: {
      pageTitle: 'Withdrawal statistics',
    },
  },
]
