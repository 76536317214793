import axiosInstance from '@/plugins/axios'

export default {

  postNextAccountPhase(accountId) {
    const url = `admin/accounts/${accountId}/levelup`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  getAccountSubtypes() {
    const url = 'admin/accounts/subtypes'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getCurrencies() {
    const url = 'admin/accounts/subtype-currencies'

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  postCreateAccount(data) {
    const url = 'admin/accounts'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  disableAccount(accountId) {
    const url = `admin/accounts/${accountId}/disable`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  duplicateAccount(accountId) {
    const url = `admin/accounts/${accountId}/duplicate`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  copyAccount(accountId, data) {
    const url = `admin/accounts/${accountId}/copy`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  postTagsToAccounts(data) {
    const url = 'admin/accounts/tags'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  deleteAccounts(data) {
    const url = 'admin/accounts/delete'

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  enableAccount(accountId) {
    const url = `admin/accounts/${accountId}/activate`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  resetAccountPwd(accountId) {
    const url = `admin/accounts/${accountId}/renew-password`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  deleteAccount(accountId) {
    const url = `admin/accounts/${accountId}`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  requestAventus(accountId) {
    const url = `admin/accounts/${accountId}`

    return axiosInstance.patch(url, { equity_check: 'requested' })
      .then(response => response.data)
  },

  removeFundedCertificate(id) {
    const url = `admin/accounts/${id}/remove-files`

    return axiosInstance.delete(url)
      .then(response => response.data)
  },

  forceCompleteAccount(id) {
    const url = `admin/accounts/${id}/force-complete`

    return axiosInstance.post(url)
      .then(response => response.data)
  },

  getAccountChildren(id) {
    const url = `admin/accounts/${id}/children`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  getAventusReport(id, type, accountId) {
    const url = `admin/equity-report/${id}`

    return axiosInstance.get(url, {
      responseType: 'blob',
      params: { type },
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `REPORT-${accountId}.${type}`)
        document.body.appendChild(link)
        link.click()
      })
  },

  getAventusRelativeReport(id, type, accountId) {
    const url = `admin/relative-report/${id}`

    return axiosInstance.get(url, {
      responseType: 'blob',
      params: { type },
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `RELATIVE-REPORT-${accountId}.${type}`)
        document.body.appendChild(link)
        link.click()
      })
  },

  getAccountResetEquities(id) {
    const url = `admin/accounts/${id}/equity-reset`

    return axiosInstance.get(url)
      .then(response => response.data)
  },

  postAccountResetEquities(id, data) {
    const url = `admin/accounts/${id}/equity-reset`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  patchEquityHistory(equityId, data) {
    const url = `admin/accounts/${equityId}/equities`

    return axiosInstance.patch(url, data)
      .then(response => response.data)
  },

  getCertificate(accountId, color = 'dark', type = 'a4', size = 'small', forceGenerate = false) {
    const url = `admin/accounts/${accountId}/certificate`

    return axiosInstance.get(url, {
      responseType: 'blob',
      params: {
        size, color, type, forceGenerate,
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${accountId}.${size === 'print' ? 'pdf' : 'jpg'}`) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  },

  depositWithdrawFromAccount(accountId, data) {
    const url = `admin/accounts/${accountId}/deposit-withdraw`

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },
}
