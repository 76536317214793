export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/user/UserIndex.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users-beta',
    name: 'users-beta',
    component: () => import('@/views/user/UserIndexBeta.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users-external',
    name: 'users-external',
    component: () => import('@/views/user/UserExternalIndex.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:id',
    name: 'user-detail',
    component: () => import('@/views/user/detail/UserDetailIndex.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          active: false,
          to: { name: 'users-beta' },
        },
        {
          text: 'User detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users-ext/:id',
    name: 'user-detail-ext',
    component: () => import('@/views/user/detail/UserDetailExternalIndex.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Users',
          active: false,
          to: { name: 'home' },
        },
        {
          text: 'User detail',
          active: true,
        },
      ],
    },
  },
]
