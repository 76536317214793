export default {
  id: String,
  user: Object,
  aff_custom_key: String,
  referral_custom_key: String,
  affiliate_type: String,
  first_profit: Number,
  first_profit_expire_after: Number,
  giveaway_after: Number,
  max_discount_codes: Number,
  min_payout: Number,
  payout_after: Number,
  profit: Number,
  createdAt: v => v,
}
