export default {
  id: String,
  firstName: String,
  lastName: String,
  email: String,
  account_count: Number,
  spend: Number,
  withdraw: Number,
  profit: Number,
}
