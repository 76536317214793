export default {
  id: String,
  name: String,
  active: Boolean,
  value_string: String,
  createdAt: v => v,
  users: Object,
  products: Object,
  codes: Object,
  absolute_value: Number,
  relative_value: Number,
  registeredAfter: v => v,
}
