export default [
  {
    path: '/marketing-statistics',
    name: 'marketing-statistics',
    component: () => import('@/views/statistics/marketing-statistics/MarketingStatisticsIndex.vue'),
    meta: {
      pageTitle: 'Marketing statistics',
    },
  },
]
