export default [
  {
    path: '/refunds',
    name: 'refunds',
    component: () => import('@/views/refund/RefundIndex.vue'),
    meta: {
      pageTitle: 'Refunds',
      breadcrumb: [
        {
          text: 'Refunds',
          active: true,
        },
      ],
    },
  },
]
