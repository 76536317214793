// model for selecting user from affiliate
export default {
  0: Object,
  //   expected aff properties in 0: object
  //   id: String,
  //   user: Object,
  //   createdAt: v => v,
  //   sign_up_at: v => v,
  //   aff_key: String,
  status: String,
}
