import { formatDateTime } from '@/plugins/formaters'

export default {
  id: String,
  firstName: String,
  lastName: String,
  full_name: String,
  email: String,
  phone: String,
  address: String,
  country: Object,
  tags: Object,
  createdAt: v => v,
  profileImage: (v, item) => (item.profile_image ? `${process.env.VUE_APP_API_URL}users/${item.id}/avatar/${item.profile_image}` : null),
  kyc_at: v => v,
  verifications: Object,
  verification_data: Object,
  purchase_disabled: Boolean,
  allowedVpn: Boolean,
  comment: String,
  risk_score: Number,
  risk_score_results: Object,
  extraTags: (v, item) => {
    const tags = []
    if (item.kyc_at && item.verification_data !== null) tags.push(`Verified ${formatDateTime(item.kyc_at)}`)
    if (item.kyc_at && item.verification_data === null) tags.push(`Verified by support ${formatDateTime(item.kyc_at)}`)
    if (item.purchase_disabled) tags.push({ name: 'Purchase disabled', variant: 'light-danger' })
    return tags
  },
}
